import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import { Grid, Typography, makeStyles, Box } from '@material-ui/core';

import { useMemo } from 'react';
import { ITheme } from '../../assets/themes';
import FileUploadIcon from '../../assets/icons/file-upload.svg';
import Show from '../show';
import Button from './default';
import { FileList } from './FileList';

export interface FileUploadProgress {
  total?: number;
  loaded?: number;
  status?: 'loading' | 'succeeded' | 'failed' | 'cancelled';
  updatedAt?: number;
  handle?: string;
  name: string;
}

const useStyles = makeStyles((theme: ITheme) => ({
  wrapper: {
    backgroundColor: theme.palette.primary.extraLight,
    borderRadius: 10,
    padding: theme.spacing(7),
    border: `1px dashed ${theme.palette.primary.light}`,
  },
  mainBox: {
    padding: theme.spacing(3, 0),
    position: 'relative',
    overflow: 'hidden',
    borderBottom: '1px solid #E6E7EF',
  },
}));

interface FileUploadBoxProps {
  value: Array<FileUploadProgress>;
  onChange: (files: Array<File>) => void;
  onRemove: (progress?: FileUploadProgress, index?: number) => void;
  onCancel: (progress: FileUploadProgress, index: number) => void;
  onRetry: (progress: FileUploadProgress, index: number) => void;
}

export const FileUploadBox = ({
  value,
  onChange,
  onRemove,
  onCancel,
  onRetry,
}: FileUploadBoxProps) => {
  const classes = useStyles();
  const attachments = value;

  const handleDrop = React.useCallback(
    (acceptedFiles: File[]) => {
      onChange(acceptedFiles);
    },
    [onChange],
  );

  const { getInputProps, getRootProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: handleDrop,
  });

  const succeededFiles = useMemo(
    () => attachments.filter((att) => att.status === 'succeeded'),
    [attachments],
  );

  return (
    <Box className={classes.mainBox}>
      <Grid container direction="column" spacing={3}>
        <Grid item container spacing={3} direction="column">
          <Grid item xs={attachments.length ? 6 : 12}>
            <Box className={classes.wrapper} {...getRootProps()}>
              <input {...getInputProps()} />
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                spacing={3}
              >
                <Grid item>
                  <img src={FileUploadIcon} alt="File Upload" />
                </Grid>
                <Grid item>
                  <Typography
                    variant="h6"
                    color="textPrimary"
                    textAlign="center"
                    fontWeight="fontWeightBold"
                  >
                    Drag and drop file(s) here{' '}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.hint"
                    textAlign="center"
                    mt={0.5}
                  >
                    Please upload in CSV/Excel or PDF format
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="h6"
                    color="textPrimary"
                    textAlign="center"
                    fontWeight="fontWeightRegular"
                  >
                    or
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    onClick={open}
                  >
                    Browse
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid
            item
            xs={attachments.length ? 6 : false}
            sx={{
              display: attachments.length ? 'block' : 'none',
            }}
          >
            <Box position="relative" height={'100%'}>
              <Box pb={1}>
                <Show when={succeededFiles.length}>
                  <FileList
                    title={`Uploaded Files (${succeededFiles.length})`}
                    files={succeededFiles}
                    onRemove={onRemove}
                    onCancel={onCancel}
                    onRetry={onRetry}
                    onDownload={() => null}
                  />
                </Show>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FileUploadBox;
